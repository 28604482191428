var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"PWYEUFtx93ZNpUpDKfm2q"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

// People download pages with articles to read later; we don't expect Plaudit to
// work there, so ignore error messages sent from there:
if (document.location.protocol !== "file:") {
  const sentryOptions: Parameters<typeof Sentry.init>[0] = ({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,

    environment:
      process.env.NODE_ENV === "production" &&
      !["plaudit.onrender.com", "plaudit.pub"].includes(
        document?.location.hostname
      )
        ? process.env.NEXT_PUBLIC_BRANCH
        : process.env.NODE_ENV,

    release: process.env.NEXT_PUBLIC_RELEASE
      ? process.env.NEXT_PUBLIC_RELEASE
      : "local",

    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1,

    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: false,

    replaysOnErrorSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: process.env.NODE_ENV === "development" ? 1 : 0.1,

    // You can remove this option if you're not planning to use the Sentry Session Replay feature:
    integrations: [
      new Sentry.Replay({
        // Additional Replay configuration goes in here, for example:
        maskAllText: true,
        blockAllMedia: true,
        networkDetailAllowUrls: ["/api/endorsements/"],
      }),
    ],

    ignoreErrors: [
      // Unfortunately I'm not 100% sure we're not sending these, but since
      // these are being sent from the client-side and contain no information
      // whatsoever to help debug them, and that they're happening relatively
      // rarely (0-2 times a day), I'm going to assume it's some browser
      // extension throwing undefined:
      "Non-Error promise rejection captured with value: undefined",
      // These seem to be caused by Outlook's SafeLink crawlers. See
      // https://github.com/getsentry/sentry-javascript/issues/3440#issuecomment-1233146122
      "Object Not Found Matching Id",
      // The following `ignoreErrors` are based on
      // https://gist.github.com/impressiver/5092952, as recommended by
      // https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
      // Random plugins/extensions
      "top.GLOBALS",
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      "originalCreateNotification",
      "canvas.contentDocument",
      "MyApp_RemoveAllHighlights",
      "http://tt.epicplay.com",
      "Can't find variable: ZiteReader",
      "jigsaw is not defined",
      "ComboSearch is not defined",
      "http://loading.retry.widdit.com/",
      "atomicFindClose",
      // Facebook borked
      "fb_xd_fragment",
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
      "bmi_SafeAddOnload",
      "EBCallBackMessageReceived",
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      "conduitPage",
    ],
    // `denyUrls` based on https://gist.github.com/impressiver/5092952, as
    // recommended by https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Browser extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^safari-extension:\/\//i,
      /^moz-extension:\/\//i,
      /^chrome-extension:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
  });

  if (document.referrer === "http://baidu.com/") {
    // This error is generated only on Chrome, with the above Referer header,
    // so this seems like an error caused by a particular client configuration:
    sentryOptions.ignoreErrors?.push(
      "ReferenceError: globalThis is not defined",
    );
  }

  Sentry.init(sentryOptions);
}
